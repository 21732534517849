
.pay-card {
  max-width: 550px;
  .pay-card-content {

    .input-border {
      border: 2px solid $secondary-light;
      border-radius: 0.3em;
      height: 3.5em;
    }

    .select-border {
      border: 2px solid $secondary;
      border-radius: 0.3em;
      height: 3.5em;
    }

    .currency-border {
      border: 2px solid $secondary-light;
      border-radius: 0.3em;
      height: 3.5em;
    }

    .pay-button {
      height: 3.5em;
      border-radius: 0.3em;
    }

    .custom-badge-danger {
      border: 1px dotted $secondary-light;
      color: $danger;
      border-radius: 0.5em;
      padding: 3px;
    }

    .custom-badge-danger-active {
      border: 3.5px solid $danger;
      color: $danger;
      border-radius: 0.5em;
      padding: 3px;
    }

    .custom-badge-success {
      border: 1px dotted $secondary-light;
      color: $success;
      border-radius: 0.5em;
      padding: 3px;
    }

    .custom-badge-success-active {
      border: 3.5px solid $success;
      color: $success;
      border-radius: 0.5em;
      padding: 3px;
    }

    .custom-badge-secondary {
      border: 1px dotted $secondary-light;
      color: $secondary;
      border-radius: 0.5em;
      padding: 3px;
    }

    .custom-badge-secondary-active {
      border: 3.5px solid $secondary;
      color: $secondary;
      border-radius: 0.5em;
      padding: 3px;
    }

    .custom-badge-warning {
      border: 1px dotted $warning;
      color: $warning;
      border-radius: 0.5em;
      padding: 3px;
    }

    .custom-badge-warning-active {
      border: 3.5px solid $warning;
      color: $warning;
      border-radius: 0.5em;
      padding: 3px;
    }

    .custom-badge-size {
      font-size: 14px;
    }

    .custom-badge-size{
      font-size: 13px;
    }

    .pay-input-group{
      background-color: $secondary-light;

      .label{
        color: white;
      }
    }
  }
}

