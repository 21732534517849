.form-wrapper {
  background: #fff;
  width: 800px;
  margin: 0 auto;
  font-size: 9px;
  color: $gray-800;

  .title {
    font-size: 1.5em;
    font-weight: bold;
  }

  .sub-title {
    font-size: 1.2em;
    font-weight: bold;
  }

  .flexTable {
    @extend .flex-row,.d-flex;
    margin-top: -1px;
    .flexCell {
      padding: 0;
      border: 1px solid $gray-200;
      border-right: none;
      padding: $spacer / 5.5 ;
      &:last-child {
        border-right: 1px solid $gray-200;
      }
    }
  }

  .cell{
    border: 1px solid $gray-200;
    padding: $spacer / 4 $spacer;
  }

  .image{
    width:480px;
    height: 310px;
    padding: 0;
    position: relative;
    margin-top: -10px;
    margin-bottom: 10px;
    .imageSvg{
      position: absolute;
      width: 480px;
      height: 310px;
      padding: 0;
      top:2px;
      left:-3px;
      cursor: pointer;
    }
  }

  .modal{

  }

  .touchArea{
    background-color: rgba(149, 192, 208, 0.5);
    position: absolute;
    width:100%;
    height: 100%;
    top:0;
    left: 0;
    //opacity: 0;
    z-index: 1;

    &:active{
      background-color: #7d7d7d;
      opacity: 0.5;
    }
  }

  .touchAreaSelect{
    background-color: rgba(149, 192, 208, 0.5);
    position: absolute;
    width:100%;
    height: 100%;
    top:0;
    left: 0;
    opacity: 0;
    z-index: 1;

    &:active{
      background-color: #7d7d7d;
      opacity: 0.5;
    }
  }

  .touchAreaDisabled{
    background-color: $light;
    position: absolute;
    width:100%;
    height: 100%;
    top:0;
    left: 0;
    opacity: 0.5;
    z-index: 1;

    &:active{
      background-color: #7d7d7d;
      opacity: 0.5;
    }
  }

  .signArea{
    width:40%;
    height: 100%;
    display: inline-block;
    position: absolute;
    top:0;
    left:45px;
    border: 2px dotted $gray-400;
    cursor: pointer;
    &:active{
      background-color: #7d7d7d;
    }
  }

  .description{
    background-color: $light;
    border-radius: 10px;

    span{
      font-weight: bolder;
    }
  }

  .custom-text{
    margin-bottom: 2px;
  }

}

.printing {
  .form-wrapper {
    margin: 0;
  }



}