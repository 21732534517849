
.left-to-right{
  position:absolute;
  -webkit-animation:linear infinite alternate;
  -webkit-animation-name: run;
  -webkit-animation-duration: 5s;
}

@-webkit-keyframes run {
  0% { left: 0;}
  100%{ left : 100%;}
}