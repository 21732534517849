
.image-card{
   height: 10em;
   border-style: dotted;
   min-width: 150px;

}

.image-card:hover{
  background-color: $secondary-light;
}

.image-loaded-card{
  height: 10em;
  border: 2px solid $success;
}
