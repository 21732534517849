
.confirm-card{
  border: 1px solid $secondary-light;
  border-radius: 0.4em;
  width: 80vw;
  margin-left: auto;
  margin-right: auto;
  padding: 1em;
  margin-bottom: 1em;

  .title{
    font-size: 16px;
    display: block;
  }

  .item{
    display: block;
  }
}