
.driver-edit-dialog{
  .modal-wrapper{
     width: 280px;
     height: 280px;
   }

  .title{
    position: relative;
    margin-top: 7%;
    margin-left: 7%;
    font-size: large;
    font-weight: 500;
  }
}
